import type { ISiteApis } from '@wix/yoshi-flow-editor';

import {
  GROUPS_APP_ID,
  MEMBERS_APP_DEF_ID,
  PAID_PLANS_APP_DEF_ID,
} from '../constants';

/**
 * Resolve verticals base urls
 * please bear in mind that object keys should be named the same as routes names.
 * router will inject baseUrls to them during registration stage
 */
export async function resolveVerticalsBaseUrls(api: ISiteApis) {
  const [group, groups, pricingPlans, members] = await Promise.all([
    getGroupPageUrl(api),
    getGroupListPageUrl(api),
    getPricingPlansPageUrl(api),
    getProfilePageUrl(api),
  ]);

  return {
    group,
    groups,
    members,
    'pricing-plans': pricingPlans,
  };
}

function getGroupPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_ID,
    },
    '/group',
  );
}

function getGroupListPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'groups',
      appDefinitionId: GROUPS_APP_ID,
    },
    '/groups',
  );
}

function getPricingPlansPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'membership_plan_picker_tpa',
      appDefinitionId: PAID_PLANS_APP_DEF_ID,
    },
    '/plans-pricing',
  );
}

async function getProfilePageUrl(site: ISiteApis) {
  try {
    const membersApi = await site.getPublicAPI(MEMBERS_APP_DEF_ID);

    return membersApi
      .getMemberPagePrefix({ type: 'public' })
      .then((data: { prefix: string }) => `/${data.prefix}`)
      .catch(() => '/profile');
  } catch (err) {
    return '/profile';
  }
}

async function getComponentUrl(
  site: ISiteApis,
  sectionIdentifier: {
    appDefinitionId: string;
    sectionId: 'groups' | 'group' | 'membership_plan_picker_tpa' | 'about';
  },
  fallback: string,
) {
  try {
    const { relativeUrl } = await site.getSectionUrl(sectionIdentifier);

    return relativeUrl || fallback;
  } catch {
    return fallback;
  }
}
